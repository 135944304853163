<template>
    <v-container>
        <form slot="leftContainer" autocomplete="nope" data-vv-scope="form-2" @submit.prevent="addCotizacion('form-2')">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>Generar Cotizaciones</b>
                </v-card-title>
                <v-card-text class="pa-4">
                    <v-row dense align="center">
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="form.idTipoComprobante"
                                v-validate="'required'"
                                :items="tipoComprobanteFact"
                                item-text="descripcion"
                                placeholder="Contado"
                                item-value="id"
                                :error-messages="errors.collect('form.idTipoComprobante')"
                                label="Tipo Comprobante"
                                data-vv-name="form.idTipoComprobante"
                                data-vv-as="Tipo"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="form.idTipoPago"
                                v-validate="'required'"
                                :items="tipoPago"
                                item-text="descripcion"
                                placeholder="Contado"
                                item-value="id"
                                :error-messages="errors.collect('form.idTipoPago')"
                                label="Tipo pago"
                                data-vv-name="form.idTipoPago"
                                data-vv-as="Tipo"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-menu
                                dense
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        v-model="form.fechaEmision"
                                        label="Fecha de Emisión"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        data-vv-name="form.fechaEmision"
                                        data-vv-as="fecha Emisión"
                                        :error-messages="errors.collect('form.fechaEmision')"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker dense v-model="form.fechaEmision" no-title locale="es" @input="menu = false" />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-menu
                                dense
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        v-model="form.fechaVencimiento"
                                        label="Fecha de Vencimiento"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        data-vv-name="form.fechaVencimiento"
                                        data-vv-as="fecha cotizacion"
                                        :error-messages="errors.collect('form.fechaVencimiento')"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker dense v-model="form.fechaVencimiento" no-title locale="es" @input="menu2 = false" />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-text-field
                                dense
                                v-model="form.largo"
                                data-vv-name="form.largo"
                                :error-messages="errors.collect('form.largo')"
                                label="Largo"
                                @keypress="isNumber"
                            />
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-text-field
                                dense
                                v-model="form.ancho"
                                data-vv-name="form.ancho"
                                :error-messages="errors.collect('form.ancho')"
                                label="Ancho"
                                @keypress="isNumber"
                            />
                        </v-col>
                        <v-col cols="12" sm="1">
                            <v-text-field
                                dense
                                v-model="form.alto"
                                data-vv-name="form.alto"
                                :error-messages="errors.collect('form.alto')"
                                label="Alto"
                                @keypress="isNumber"
                            />
                        </v-col>

                        <!-- <v-col cols="12" sm="2">
                          <v-text-field
                              dense
                              data-vv-name="form.tipoCambio"
                              data-vv-as="form.tipoCambio"
                              v-model="form.tipoCambio"
                              label="Tipo Cambio"
                              @keypress="isNumber"
                          />
                      </v-col> -->
                        <SelectProductos></SelectProductos>
                    </v-row>
                    <!-- CLIENTE -->
                    <v-row dense align="center" class="py-4">
                        <v-col cols="12"> <v-checkbox dense v-model="form.tieneDatosCliente" label="¿Tengo datos del cliente?"></v-checkbox></v-col>
                        <v-col cols="12" v-if="form.tieneDatosCliente">
                            <v-row align="center">
                                <v-col cols="12" sm="5">
                                    <v-autocomplete
                                        v-validate="'required'"
                                        dense
                                        v-model="form.idCliente"
                                        :items="listaClientes"
                                        item-text="rename"
                                        placeholder="INNOUT SAC"
                                        item-value="id"
                                        :error-messages="errors.collect('form.idCliente')"
                                        label="Buscar Clientes"
                                        data-vv-name="form.idCliente"
                                        data-vv-as="Tipo"
                                        filled
                                        rounded
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" class="py-2">
                                    <v-btn dark small @click="openModalProveedor = true">
                                        <b>Agregar Cliente</b>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-else>
                            <v-row>
                                <v-col cols="12" sm="9">
                                    <v-text-field dense v-model="form.nameDefault" label="Nombre Cliente" placeholder="Innout Sac"
                                /></v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12" sm="3" v-if="form.idTipoMoneda === 2">
                            <v-text-field
                                v-validate="'decimal'"
                                data-vv-name="tipoCambio"
                                :error-messages="errors.collect('tipoCambio')"
                                placeholder="Tipo de cambio del día"
                                dense
                                @keypress="isNumber"
                                v-model="form.tipoCambio"
                                label="Tipo de Cambio Referencial $"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="isDepartamento"
                                :items="departamentos"
                                item-text="name"
                                placeholder="Departamento"
                                item-value="id"
                                label="Departamento"
                                autocomplete="nope"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                autocomplete="nope"
                                dense
                                v-model="isProvincia"
                                :items="provincias"
                                item-text="name"
                                placeholder="provincia"
                                item-value="id"
                                label="Provincia"
                            /> </v-col
                        ><v-col cols="12" sm="3">
                            <v-autocomplete
                                autocomplete="nope"
                                dense
                                v-model="isDistrito"
                                :items="distritos"
                                item-text="name"
                                item-value="id"
                                label="Distrito"
                            />
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field dense v-model="form.direccion" label="Dirección de envío" placeholder="jr jose de la mar 469" />
                        </v-col>

                        <v-col cols="12" sm="2"> <v-text-field dense v-model="form.telefono" label="Telf Contacto" placeholder="986193329" /></v-col>
                        <v-col cols="12" sm="3"> <v-text-field dense v-model="form.correo" label="Correo" placeholder="hola@innout.pe" /></v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field dense v-model="form.diasEntrega" label="Tiempo de entrega en días" @keypress="isNumber" placeholder="5"
                        /></v-col>
                        <v-col cols="12" sm="12"> <v-text-field dense v-model="form.formaPago" label="Forma de Pago" placeholder="" /></v-col>
                        <v-col cols="12" sm="12">
                            <v-textarea dense v-model="form.description" rows="2" auto-grow label="Consulta/Pregunta Cliente" placeholder=""
                        /></v-col>
                        <v-col cols="12" sm="4">
                            <v-select
                                clearable
                                v-validate="'required'"
                                :error-messages="errors.collect('form.origen')"
                                data-vv-name="form.origen"
                                data-vv-as="origen"
                                dense
                                v-model="form.origen"
                                :items="listOrigen"
                                placeholder="SELECCIONAR ORIGEN"
                                label="SELECCIONAR ORIGEN"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="form.origen == 'OTROS'">
                            <v-text-field
                                v-validate="'required'"
                                :error-messages="errors.collect('form.altOrigen')"
                                data-vv-name="form.altOrigen"
                                data-vv-as="altOrigen"
                                dense
                                v-model="form.altOrigen"
                                label="iNGRESAR ORIGEN"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field dense v-model="form.observaciones" label="Detalles Extras (Se muestra en el PDF)" placeholder=""
                        /></v-col>
                        <b class="red--text"> {{ form.isSendMail ? '¡Ya se envió un email anteriormente!' : '' }} </b>
                        <v-col class="grey lighten-4 pa-2">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense v-model="form.telefono" label="Telf. Celular" placeholder="" />
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-textarea dense v-model="form.urlProducto" label="URL producto" placeholder="" />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-textarea dense v-model="mensajeNotificacion" label="Notas Adicionales" placeholder="" />
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-btn @click="sendWhatsapp" small dark color="green">
                                        <b>Enviar Whatsapp</b>
                                    </v-btn>
                                </v-col></v-row
                            >
                        </v-col>

                        <v-col cols="12" sm="12"> <v-switch v-model="form.sendMail" label="Enviar Correo?"></v-switch></v-col>
                    </v-row>
                    <v-col cols="12" sm="12">
                        <h3 class="text-right">
                            Importe Total {{ form.idTipoMoneda === 1 ? 'S/.' : '$' }}
                            {{ form.idTipoMoneda === 1 ? `${importe.sumaSoles}` : `${importe.sumaDolares}` }}
                        </h3>
                    </v-col>

                    <v-col cols="12" sm="6" class="mt-2 align-right">
                        <v-btn type="submit" large :loading="loading" dark :color="step == 1 ? 'primary' : 'orange'" block>
                            <b>{{ step == '1' ? 'GENERAR COTIZACION' : 'Actualizar' }}</b>
                        </v-btn>
                    </v-col>
                </v-card-text>
            </v-card>
        </form>
        <v-card slot="rightContainer" outlined>
            <v-dialog v-model="modalReserva.state" scrollable max-width="1024" persistent transition="dialog-bottom-transition">
                <ModalReserva v-if="modalReserva.state" />
            </v-dialog>
            <div>
                <v-container fluid>
                    <v-card>
                        <v-card-text>
                            <v-row align="center" justify="center">
                                <v-col cols="12" sm="6">
                                    <v-row class="grey lighten-4" align="center" justify="center">
                                        <v-col cols="12"> <strong>FILTROS</strong></v-col>
                                        <v-col cols="12" sm="6">
                                            <v-menu
                                                ref="menu3"
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                            >
                                                <template #activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="fechaFiltro"
                                                        hide-details="auto"
                                                        dense
                                                        label="FILTRAR POR MES"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="fechaFiltro" type="month" no-title scrollable>
                                                    <v-spacer />
                                                    <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                                                    <v-btn color="primary" @click="getListaCot"> BUSCAR </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-btn class="mx-1" small color="green" dark @click="openDocumento = true"> BUSCAR COTIZACIÓN </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-row>
                                        <v-col cols="12"> <strong>ACTIONS</strong></v-col>

                                        <v-col cols="6" align="center" justify="center" v-if="optionalRole.isDownload"
                                            ><v-btn class="mx-1" small color="grey" dark @click="downloadHandle">
                                                DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6" class="text-center">
                                            <v-btn small color="blue" :loading="loadingCotizacion" dark @click="getListaCot(true)">
                                                RELOAD <v-icon right dark> mdi-autorenew </v-icon></v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
            </div>
            <v-card-title class="grey lighten-4">
                <b>LISTA DE COTIZACIONES</b>
                <v-spacer />
                <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table
                            multi-sort
                            :loading="loadingCotizacion"
                            :search="search"
                            :headers="headers"
                            :items="_listaCotizaciones"
                            class="elevation-1"
                            :items-per-page="20"
                        >
                            <template v-slot:[`item.idVenta`]="{ item }">
                                <v-chip color="green" dark v-if="item.idVenta"> VENDIDO T-{{ item.idVenta }} </v-chip>
                            </template>

                            <template v-slot:[`item._id`]="{ item }">
                                <v-chip :color="item.origen == 'WEB' || item.idColaborador == 89 ? 'yellow' : 'green'" dark>
                                    {{ item.id }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="green" small @click="editItem(item)">mdi-pencil</v-icon>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="blue" small @click="printItem(item)">mdi-cloud-print</v-icon>
                                    </template>
                                    <span>Imprimir</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="orange" small @click="openModalVenta(item)">mdi-cart</v-icon>
                                    </template>
                                    <span>Generar Venta</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="orange" small @click="changeDollars(item)">S/.</v-icon>
                                    </template>
                                    <span>Cambiar a Soles</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            :color="item.isNotification == 1 ? 'green' : 'red'"
                                            small
                                            @click="NotificationItem(item)"
                                            >mdi-bell</v-icon
                                        >
                                    </template>
                                    <span>Notificación</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <Documento :open="openDocumento" @handle-search="getCotByDoc" @closeModal="closeDocumento" />

            <v-dialog persistent dense v-model="openModalProveedor">
                <CrearCliente @getIdClient="fnListener" @updateClient="fnUpdate" @cleanCache="clientCache" />
            </v-dialog>
            <v-dialog v-model="dialog3" max-width="300px">
                <v-card>
                    <v-card-title>
                        <span>Desea Crear una Venta?</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="primary" text @click="dialog3 = false"> Close </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="actionModalVenta"> CREAR </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="openChangeDollars" max-width="290">
                <v-card>
                    <v-col>
                        <v-row>
                            <v-col cols="12">
                                <form @submit.prevent="cambiarDolar" ref="formTipoCambio">
                                    <v-col class="text-h6 pa-1" cols="12">CAMBIO A SOLES</v-col>
                                    <v-text-field class="px-3" @keypress="isNumber" v-model="changeTipoCambio" label="Tipo de Cambio" required></v-text-field>
                                    <v-col cols="12" sm="3" class="text-center">
                                        <v-btn block color="secondaryTheme" dark type="submit">Cambiar</v-btn>
                                    </v-col>
                                </form>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="cambiarDolar"> Agree </v-btn>
                  </v-card-actions> -->
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>
<script>
const { formatInTimeZone } = require('date-fns-tz')
import { mapState, mapGetters, mapMutations } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { sortItems, exportXLSX } from '@/utils/genericUtils'
import CrearCliente from '../../components/clientes/CrearCliente.vue'
import { format } from 'date-fns'
import ModalReserva from '@/components/modals/ModalReserva'
import Documento from '@/components/modals/Documento'
import SelectProductos from '@/components/selects/SelectProductos'

export default {
    components: {
        ModalReserva,
        SelectProductos,
        Documento,
        CrearCliente
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            mensajeNotificacion: ``,
            diasEntrega: 10,
            dialog3: false,
            openChangeDollars: false,
            itemsChageDollars: null,
            changeTipoCambio: null,
            stateUpdate: false,
            fechaFiltro: new Date().toISOString().substr(0, 7),
            openDocumento: false,
            isDepartamento: null,
            isProvincia: null,
            isDistrito: null,
            menu: false,
            menu2: false,
            menu3: false,
            formClient: DefaultForm.formClient(),
            search: '',
            loadingCotizacion: false,
            step: 1,
            fecha: format(new Date(), 'yyyy-MM-dd'),
            loading: false,
            form: DefaultForm.formCotizacion(),
            openModalProveedor: false,
            loading2: false,
            headers: [
                { text: '', value: 'action', sortable: false },
                { text: 'status', value: 'idVenta' },
                { text: 'Nro Cot', value: '_id' },
                { text: 'Usuario Creación', value: 'nombreColaboradorCreacion' },
                { text: 'Usuario Asignado', value: 'nombreColaborador' },
                { text: 'fec. Emision', value: 'fechaEmision' },
                { text: 'Hora Emision', value: 'horaEmision' },
                { text: 'Cliente', value: 'full_name' },
                { text: 'Doc. Cliente', value: 'numeroDocumento' },
                { text: 'Descripción', value: 'productoDetalle' },
                { text: 'S/', value: 'montoSoles' },
                { text: '$', value: 'montoDolares' }
            ]
        }
    },
    computed: {
        ...mapState('utilities', ['tipoComprobanteFact', 'tipoPago', 'tipoMoneda', 'tipoDocumento', 'tipoBancoDestino', 'listOrigen']),
        ...mapState('users', ['user']),
        ...mapState('pagos', ['bancoTipoCambio', 'external_tipoCambio']),
        ...mapState('sedes', ['departamentos', 'provincias', 'distritos']),
        ...mapGetters('clientes', ['listaClientes']),
        ...mapGetters('users', ['optionalRole']),

        ...mapState('ventas', ['productos', 'modalReserva']),
        ...mapGetters('cotizaciones', ['listaCotizaciones']),

        _listaCotizaciones() {
            const itemCotizacion = []
            try {
                this.listaCotizaciones.forEach((x) => {
                    const itemsProductos = x.itemsProductos ? JSON.parse(x.itemsProductos) : []
                    const detalle = itemsProductos.map((y) => y.description)

                    const isFind = this.listOrigen.find((y) => y == x.origen)
                    const fechaCompleta = new Date(x.created_at)
                    const horaFormateada = `${fechaCompleta.getUTCHours()}:${fechaCompleta.getUTCMinutes().toString().padStart(2, '0')}`
                    const timePeriod = fechaCompleta.getUTCHours() >= 12 ? 'PM' : 'AM'
                    const cotizacion = {
                        ...x,
                        horaEmision: `${horaFormateada} ${timePeriod}`,
                        origen: isFind || 'OTROS',
                        altOrigen: isFind ? null : x.origen,
                        productoDetalle: detalle.join().slice(0, 30),
                        tieneDatosCliente: Boolean(x.idCliente),
                        full_name: x.full_name || x.nameDefault,
                        _id: String('000000' + x.id).slice(-6),
                        nombreColaboradorCreacion: x.nombreColaboradorCreacion ?? 'SYSTEM'
                    }
                    itemCotizacion.push(cotizacion)
                })
                const optionalRole = JSON.parse(this.user.optionalRole)

                const listWeb = itemCotizacion.filter((el) => el.origen == 'WEB' && !el.idUsuarioModificacion)
                const itemJunior = optionalRole.newVendor
                    ? itemCotizacion.filter((x) => x.idUsuarioModificacion == this.user.id).concat(listWeb)
                    : itemCotizacion
                return itemJunior

                return itemCotizacion
            } catch (error) {
                //console.log('lol ', error)
            }
        },
        importe() {
            let sumaSoles = 0
            let sumaDolares = 0
            this.productos.map((x) => {
                if (x.idTipoMoneda === 1) {
                    //console.log('sumaSolesx', x)
                    sumaSoles += Number(x.subTotal)
                } else {
                    //console.log('sumaDolares', x)

                    sumaDolares += Number(x.subTotal)
                }
            })
            return {
                sumaSoles: sumaSoles.toFixed(2),
                sumaDolares: sumaDolares.toFixed(2)
            }
        },
        _idCliente() {
            return this.form.idCliente
        },
        _stateUpdate() {
            return this.stateUpdate
        }
    },
    methods: {
        ...mapMutations({
            /*             RESET_FORM: 'clientes/RESET_FORM',
          UPDATE_DATOS: 'clientes/UPDATE_DATOS', */
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        async sendWhatsapp() {
            const mensaje = `** ${this.mensajeNotificacion}`

            await this.$store.dispatch('ventas/botWhatsapp', {
                idTemplate: 10,
                telefono: `51${this.form.telefono}`,
                notasAdic: mensaje || '---',
                url: this.form.urlProducto || '---'
            })
        },
        async editItem(v) {
            const item = Object.assign({}, v)
            this.$store.commit('mainUI/OPEN_MODAL', { state: true })
            const dataProyect = JSON.parse(item.datosProyecto)
            console.log('dataProyect', dataProyect)
            console.log('item', item)
            this.form = {
                ...dataProyect,
                sendMail: false,
                isSendMail: item.sendMail,
                ...item,
                tipoCambio: item.tipoCambio
            }
            console.log('this.form----', this.form)
            if (item.ubigeo) {
                this.isDepartamento = item.ubigeo.slice(0, 2)
                this.isProvincia = item.ubigeo.slice(0, 4)
                this.isDistrito = item.ubigeo
            }
            this.$store.commit('ventas/UPDATE_PRODUCTOS', JSON.parse(item.itemsProductos))
            this.step = 2
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            window.scrollTo(0, 0)
        },
        async printItem(item) {
            console.log('item ', item)
            console.log('this.distritos---', this.distritos)
            const distrito = this.distritos.find((x) => x.id == item.ubigeo)?.name ?? ''
            console.log('distrito-.-.-.-.', distrito)
            this.$store.commit('mainUI/OPEN_MODAL', { state: true, text: 'Generando Reporte' })

            await this.$store.dispatch('reporte/reporteCotizacion', { ...item, distrito })
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },
        async createVenta(v) {
            this.dialog3 = false
            this.selectedVenta = null
            const item = Object.assign({}, v)
            //console.log('createVenta ', item)
            const festivos = []
            let diaPedido = formatInTimeZone(new Date(), 'America/Lima', 'yyyy-MM-dd HH:mm')
            diaPedido = new Date(diaPedido)
            console.log('diaPedido', diaPedido)
            function calculaEntrega(diaPedido, diasPactados, festivos) {
                let diaPropuesto = new Date(diaPedido.getFullYear(), diaPedido.getMonth(), diaPedido.getDate())

                let i = 1

                while (diasPactados > 0) {
                    let festivo = false
                    diaPropuesto = new Date(diaPedido.getFullYear(), diaPedido.getMonth(), diaPedido.getDate() + i)

                    if (diaPropuesto.getDay() > 0 && diaPropuesto.getDay() < 6) {
                        let m = diaPropuesto.getMonth()
                        let dia = diaPropuesto.getDate()

                        for (let d in festivos[m]) {
                            if (dia === festivos[m][d]) {
                                festivo = true
                                break
                            }
                        }

                        if (!festivo) {
                            diasPactados--
                        }
                    }

                    i++
                }

                return diaPropuesto
            }
            this.$store.commit('ventas/RESET_DATOS')
            this.$store.commit('mainUI/OPEN_MODAL', { state: true, text: 'Generando Venta' })
            const itemsProductos = item.itemsProductos ? JSON.parse(item.itemsProductos) : []
            let importeTotal = 0
            let idTipoMoneda = 1
            itemsProductos.map((x) => {
                idTipoMoneda = x.idTipoMoneda ? x.idTipoMoneda : 1

                importeTotal += Number(x.subTotal)
            })
            const idCotizacion = item.id
            item.id = null
            //item.idCliente = null
            const cliente = this.listaClientes.find((y) => y.id === item.idCliente)
            //form.diasEntrega
            let diasEntrega = parseInt(item.diasEntrega)
            diasEntrega = calculaEntrega(diaPedido, diasEntrega, festivos)
            console.log('diasEntrega', diasEntrega)
            // if (diasEntrega == 5) {
            //     diasEntrega = diasEntrega + 2
            // } else if (diasEntrega == 10) {
            //     diasEntrega = diasEntrega + 4
            // }
            //console.log('diasEntrega', diasEntrega)
            // let check_in = format(addBusinessDays(new Date(), diasEntrega), 'yyyy-MM-dd')
            let check_in = format(diasEntrega, 'yyyy-MM-dd')
            //console.log('check_in ', check_in)
            delete item.id
            delete item._id
            const body = {
                ...item,
                idColaborador: item.idUsuarioModificacion,
                idTipoMoneda,
                idTipoDocumento: cliente ? cliente.idTipoDocumento : null,
                tipoVenta: 2,
                idCotizacion,
                check_in,
                observation: item.observaciones,
                importeTotal,
                finalizado: 0,
                status: 1,
                productos: item.itemsProductos,
                idUserAssignment: item.idUsuarioModificacion
            }
            console.log('body-----', body)
            this.$store.commit('ventas/UPDATE_PRODUCTOS', itemsProductos)

            //console.log('body --> ', body)

            this.$store.commit('ventas/UPDATE_DATOS', body)
            this.$store.commit('ventas/SHOW_MODAL', { step: 2, state: true })

            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
        },

        async deleteItem(item) {
            await this.$store.dispatch('cotizaciones/deleteCotizacion', {
                id: item.id
            })
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Eliminado satisfactoriamente'
            })
            await this.getListaCot({ state: true })
            this.loadingCotizacion = true
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            this.loadingCotizacion = false
        },
        async NotificationItem(item) {
            console.log('item', item)
            const { telefono, itemsProductos, idCliente, datosProyecto } = item
            const findClient = this.listaClientes.find((x) => x.id == idCliente)
            console.log('findClient', findClient)
            const products = JSON.parse(itemsProductos)
            const numProducts = products.length
            const productos = ` 📦${products[0].description} ${numProducts - 1 == 0 ? '' : ` más ${numProducts - 1} producto(s) más.`}`

            await this.$store.dispatch('ventas/botWhatsapp', { idTemplate: 11, telefono: `51${telefono}`, fullName: findClient.defaultName, productos })
            await this.$store.dispatch('cotizaciones/updateCotizaciones', {
                ...item,
                itemsProductos: products,
                datosProyecto: JSON.parse(datosProyecto),
                isNotification: 1
            })
            await this.getListaCot({ state: true })
        },
        async addCotizacion(name) {
            console.log('this.$validator', this.$validator)
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    console.log('result', result)
                    let text = 'Generando Cotización'

                    if (result) {
                        try {
                            this.loading = true
                            this.$store.commit('mainUI/OPEN_MODAL', {
                                state: true,
                                text
                            })
                            const { direccion } = this.form
                            let depart = { name: '' }
                            let prov = { name: '' }
                            let distr = { name: '' }

                            if (this.isDepartamento) {
                                depart = this.departamentos.find((x) => x.id === this.isDepartamento)
                                if (this.isProvincia) {
                                    prov = this.provincias.find((x) => x.id === this.isProvincia)
                                    if (this.isDistrito) {
                                        distr = this.distritos.find((x) => x.id === this.isDistrito)
                                    }
                                }
                            }
                            const distrito = this.isDistrito ? this.distritos.find((x) => x.id == this.isDistrito)?.name : ''
                            const myTimezone = formatInTimeZone(new Date(), 'America/Lima', 'yyyy-MM-dd')
                            console.log('myTimezone', myTimezone)
                            const data = {
                                ...this.form,
                                origen: this.form.origen == 'OTROS' ? this.form.altOrigen : this.form.origen,
                                datosProyecto: {
                                    alto: this.form.alto,
                                    ancho: this.form.ancho,
                                    largo: this.form.largo
                                },
                                tipoCambio: this.form.tipoCambio,
                                idUsuarioModificacion: this.user.id,
                                direccion: direccion ? `${direccion}` : '',
                                itemsProductos: this.productos,
                                ubigeo: this.isDistrito || '',
                                distrito,
                                montoSoles: this.importe.sumaSoles,
                                montoDolares: this.importe.sumaDolares,
                                full_name: this.form.full_name || this.form.nameDefault,
                                fechaEmision: myTimezone
                            }
                            console.log('dataCotizacion', data)
                            if (this.step == 1) {
                                await this.$store.dispatch('cotizaciones/postCotizaciones', data)
                            } else {
                                await this.$store.dispatch('cotizaciones/updateCotizaciones', data)
                                text = 'Actualizado exitosamente'
                            }

                            this.form = DefaultForm.formCotizacion()
                            this.formClient = DefaultForm.formClient()

                            this.isDepartamento = '15'
                            this.isProvincia = '1501'
                            await this.getListaCot({ state: true })
                            //console.log('UPDATE_PRODUCTOS ', this.productos)
                            this.$store.commit('mainUI/OPEN_MODAL', {
                                state: false,
                                text
                            })
                            this.step = 1
                            this.loading = false
                            this.$store.commit('ventas/UPDATE_PRODUCTOS', [])
                        } catch (error) {
                            //console.log('err-> ', error)
                            text = 'Ha ocurrido un Error'
                        }
                    } else {
                        //console.log('result ', result)
                    }
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: false
                    })
                })
                .catch((e) => {
                    //console.log('Eerr ', e)
                    this.loading = false
                })
        },
        fnListener(v) {
            console.log('v', v)
            this.form.idCliente = v
            this.openModalProveedor = false
        },
        fnUpdate(v) {
            this.stateUpdate = v
        },
        cambiarDolar() {
            this.$validator.validateAll('formTipoCambio').then(async (result) => {
                console.log('this.$validator', this.$validator)
                if (result) {
                    await this.$store.dispatch('cotizaciones/changeDollars', { ...this.itemsChageDollars, tc: this.changeTipoCambio })
                    this.openChangeDollars = false
                    await this.getListaCot({ state: true })
                    //console.log('UPDATE_PRODUCTOS ', this.productos)
                }
            })
        },
        clientCache(v) {
            this.openModalProveedor = false
        },
        async getListaCot({ state = true, numeroDocumento = null }) {
            //console.log('state ', state)
            this.loadingCotizacion = true
            await this.$store.dispatch('cotizaciones/getLista', { fechaFiltro: this.fechaFiltro, state, numeroDocumento })
            this.loadingCotizacion = false
            this.menu3 = false
        },

        async getListaClientes(payload) {
            await this.$store.dispatch('clientes/getListaCli', payload)
        },
        downloadHandle() {
            this.loading = true
            const headers = [
                'id',
                'status',
                'created_at',
                'sede',
                'fechaEmision',
                'formaPago',
                'full_name',
                'nombreColaborador',
                'numeroDocumento',
                'observaciones',
                'itemsProductos'
            ]
            const arrayData = []
            this._listaCotizaciones.map((x) => {
                arrayData.push({
                    ...x,
                    itemsProductos: JSON.parse(x.itemsProductos)[0]?.description,
                    status: x.idVenta ? `VENDIDO T-${x.idVenta}` : x.id
                })
            })
            const filename = `REPORT_cotizacion_${Date.now()}`
            exportXLSX({ headers, campos: headers, arrayData }, { filename })
            this.loading = false
        },
        closeDocumento(nombreModal) {
            this[nombreModal] = false
        },
        async getCotByDoc(payload) {
            const numeroDocumento = payload.numeroDocumento
            await this.getListaCot({ state: true, numeroDocumento })
            this.openDocumento = false
        },
        async actionModalVenta() {
            await this.createVenta(this.selectedVenta)
        },
        async openModalVenta(item) {
            //console.log('item ', item)
            this.selectedVenta = item
            this.dialog3 = true
        },
        async changeDollars(item) {
            console.log('item', item)
            this.openChangeDollars = true
            this.itemsChageDollars = item
        }
    },
    watch: {
        _stateUpdate(val) {
            if (this.form.idCliente) {
                console.log('_stateUpdate', val)
                console.log('client watch')
                this.isDepartamento = null
                this.isProvincia = null
                this.isDistrito = null
                this.form.telefono = null
                this.form.correo = null
                this.form.direccion = null
                //console.log('val ', val)
                //console.log('this.listaClientes ', this.listaClientes)
                const isCliente = this.listaClientes.find((x) => x.id == this.form.idCliente)
                console.log('isCliente', isCliente)
                this.form.telefono = isCliente.telephone || null
                this.form.correo = isCliente.email || null
                this.form.idTipoComprobante = isCliente.number.length == 11 ? 1 : 2
                this.form.direccion = isCliente.address || null
                this.form.numeroDocumento = isCliente.number || ''
                this.form.full_name = isCliente.name || ''
                //console.log('isCliente.ubigeo', isCliente.district_id)
                if (isCliente.district_id) {
                    this.isDepartamento = isCliente.district_id.slice(0, 2)
                    this.isProvincia = isCliente.district_id.slice(0, 4)
                    this.isDistrito = isCliente.district_id
                }
            }
            this.stateUpdate = false
        },
        _idCliente(val) {
            console.log('_idCliente')
            if (parseInt(val)) {
                console.log('client watch')
                this.isDepartamento = null
                this.isProvincia = null
                this.isDistrito = null
                this.form.telefono = null
                this.form.correo = null
                this.form.direccion = null
                //console.log('val ', val)
                //console.log('this.listaClientes ', this.listaClientes)
                const isCliente = this.listaClientes.find((x) => x.id == val)
                console.log('isCliente ', isCliente)
                this.form.telefono = isCliente.telephone || null
                this.form.correo = isCliente.email || null
                this.form.direccion = isCliente.address || null
                this.form.numeroDocumento = isCliente.number || ''
                this.form.full_name = isCliente.name || ''
                //console.log('isCliente.ubigeo', isCliente.district_id)
                if (isCliente.district_id) {
                    this.isDepartamento = isCliente.district_id.slice(0, 2)
                    this.isProvincia = isCliente.district_id.slice(0, 4)
                    this.isDistrito = isCliente.district_id
                }
            }
        },

        productos: {
            deep: true,
            handler(val) {
                if (val.length > 0) {
                    const idTipoMoneda = val[0].idTipoMoneda
                    this.form.idTipoMoneda = idTipoMoneda
                    //  this.form.tipoCambio = idTipoMoneda === 1 ? null : null
                }
            }
        },
        modalReserva: {
            deep: true,
            handler(val) {
                //console.log('val ', val)
                if (!val.state) {
                    this.$store.commit('ventas/RESET_DATOS')
                }
            }
        },
        async isDepartamento(val) {
            //console.log('isDepartamento ', val)
            if (val) {
                await this.$store.dispatch('sedes/getProvincia', val)
            }
        },
        async isProvincia(val) {
            //console.log('provincia ', val)
            if (val) {
                await this.$store.dispatch('sedes/getDistrito', val)
            }
        }
    },
    async created() {
        this.loadingCotizacion = true
        this.$store.commit('ventas/UPDATE_PRODUCTOS', [])
        this.$store.commit('sedes/RESET_UBIGEO')
        await Promise.all([
            this.$store.dispatch('clientes/getListaCli', { active: 0 }),
            this.$store.dispatch('pagos/tipoCambio'),
            this.$store.dispatch('productos/products', { active: 1 }),
            this.$store.dispatch('sedes/getDepartamento', { online: true }),
            this.getListaCot({ state: true })
        ])
        this.form.tipoCambio = this.external_tipoCambio
        //  await this.$store.dispatch('productos/getLista', { online: false })

        //await this.getListaClientes(false)

        this.loadingCotizacion = false
    }
}
</script>
