<template>
    <form autocomplete="nope" @submit.prevent="addCliente">
        <v-card>
            <v-card-title class="grey lighten-4">
                <b>CLIENTE</b>
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-row dense align="center">
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            dense
                            v-model="formClient.idTipoDocumento"
                            v-validate="'required'"
                            :items="tipoDocumento"
                            item-text="text"
                            item-value="id"
                            :error-messages="errors.collect('formClient.idTipoDocumento')"
                            label="Tipo de documento"
                            data-vv-name="formClient.idTipoDocumento"
                            data-vv-as="Tipo"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            dense
                            v-model="formClient.numeroDocumento"
                            v-validate="'required'"
                            required
                            :error-messages="errors.collect('formClient.numeroDocumento')"
                            label="N° documento"
                            placeholder="47517690"
                            data-vv-name="formClient.numeroDocumento"
                            data-vv-as="numeroDocumento"
                            type="tel"
                            append-icon="mdi-account-search"
                            outlined
                            @click:append="getDatosExternal"
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            dense
                            v-model="formClient.full_name"
                            v-validate="'required'"
                            :error-messages="errors.collect('formClient.full_name')"
                            label="Nombre y apellido / Raz. Social"
                            placeholder="Luis Flores"
                            data-vv-name="formClient.full_name"
                            required
                            data-vv-as="nombres"
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field dense v-model="formClient.telefono" class="ld-form-input" label="Número de celular" type="tel" placeholder="986193329" />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field
                            v-model="formClient.correo"
                            class="ld-form-input"
                            label="Email"
                            type="tel"
                            placeholder="luis.flores@innout.pe"
                            data-vv-as="email"
                        />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-text-field v-model="formClient.direccion" label="Direccion" placeholder="Jr jose la mar 459" />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            dense
                            v-model="isDepartamento"
                            :items="departamentos"
                            item-text="name"
                            placeholder="Departamento"
                            item-value="id"
                            label="Departamento"
                            autocomplete="nope"
                        />
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-autocomplete
                            autocomplete="nope"
                            dense
                            v-model="isProvincia"
                            :items="provincias"
                            item-text="name"
                            placeholder="provincia"
                            item-value="id"
                            label="Provincia"
                        /> </v-col
                    ><v-col cols="12" sm="3">
                        <v-autocomplete autocomplete="nope" dense v-model="isDistrito" :items="distritos" item-text="name" item-value="id" label="Distrito" />
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-btn type="submit" :loading="loading2" dark color="primary" block>
                            <b> {{ dataPersons ? 'Actualizar' : 'Agregar' }} </b>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-btn @click="resetForm" dark color="primary" block>
                            <b> Cerrar </b>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import DefaultForm from '@/utils/defaultForm'
export default {
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            dataPersons: null,
            formClient: DefaultForm.formClient(),
            isDepartamento: null,
            isProvincia: null,
            isDistrito: null,
            loading2: false,
            condition: null,
            state: null
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento']),
        ...mapGetters('clientes', ['listaClientes']),
        ...mapState('sedes', ['departamentos', 'provincias', 'distritos']),
        ...mapState('ventas', ['datos']),

        _numeroDocumento() {
            return this.formClient.numeroDocumento
        },
        _tipoDocumento() {
            return this.formClient.idTipoDocumento
        }
    },
    methods: {
        async getDataClients() {
            if (this.formClient.idTipoDocumento && this.formClient.numeroDocumento)
                if (this.formClient.numeroDocumento.length == 8 || this.formClient.numeroDocumento.length == 11) {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando Informacion del documento',
                        color: 'green'
                    })
                    this.dataPersons = await this.$store.dispatch('clientes/getClient', this.formClient.numeroDocumento)
                    console.log('hola')
                    console.log('this.dataPersons', this.dataPersons)
                    if (this.dataPersons) {
                        this.formClient.idTipoDocumento = this.dataPersons.identity_document_type_id
                        this.formClient.full_name = this.dataPersons.name
                        this.formClient.telefono = this.dataPersons.telephone
                        this.formClient.correo = this.dataPersons.email
                        this.formClient.direccion = this.dataPersons.address
                        this.isDepartamento = this.dataPersons.department_id
                        this.isProvincia = this.dataPersons.province_id
                        this.isDistrito = this.dataPersons.district_id
                    }
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    console.log('formClient', this.formClient)
                }
        },
        async getDatosExternal() {
            if (this.formClient.numeroDocumento) {
                if (this.formClient.numeroDocumento.length >= 8 && this.formClient.idTipoDocumento) {
                    try {
                        this.$store.commit('mainUI/OPEN_MODAL', {
                            state: true,
                            text: 'Buscando en Reniec/Sunat',
                            color: 'green'
                        })
                        //console.log('hello')
                        const resultado = await this.$store.dispatch('clientes/getClienteExternal', {
                            idTipoDocumento: this.formClient.idTipoDocumento,
                            numeroDocumento: this.formClient.numeroDocumento
                        })

                        console.log('resultado ', resultado)
                        if (resultado) {
                            //console.log('find mysql')
                            //console.log('this.formClient.idTipoDocumento', this.formClient.idTipoDocumento)
                            this.formClient.full_name = resultado.nombre_o_razon_social || resultado.nombre_completo
                            this.formClient.direccion = resultado.direccion
                            this.isDepartamento = resultado.ubigeo[0]
                            this.isProvincia = resultado.ubigeo[1]
                            this.isDistrito = resultado.ubigeo[2]
                            this.condition = resultado.condicion
                            this.state = resultado.estado
                        } else {
                            const { departamento, provincia, distrito, correo } = resultado

                            try {
                                //console.log(' departamento', departamento, ' provincia', provincia, ' distrito', distrito)

                                //const fnDepa = this.departamentos.find(x => x.name.toLowerCase() == departamento.toLowerCase())
                                /* const fnDepa = this.departamentos.find(x => x.name.toLowerCase().match(`/${departamento.toLowerCase()}.`)) */
                                const fnDepa = this.departamentos.find((x) => new RegExp(departamento.toLowerCase()).exec(x.name.toLowerCase()))
                                //console.log('fnDepa ', fnDepa)

                                this.isDepartamento = fnDepa.id

                                await this.$store.dispatch('sedes/getProvincia', fnDepa.id)
                                //console.log('this.provincia', this.provincias)

                                /* const fnProv = this.provincias.find(x => x.name.toLowerCase() == provincia.toLowerCase()) */
                                const fnProv = this.provincias.find((x) => new RegExp(provincia.toLowerCase()).exec(x.name.toLowerCase()))
                                //console.log('fnProv ', fnProv)
                                this.isProvincia = fnProv.id
                                await this.$store.dispatch('sedes/getDistrito', fnProv.id)
                                const fnDist = this.distritos.find((x) => new RegExp(distrito.toLowerCase()).exec(x.name.toLowerCase()))
                                //console.log('fnDist ', fnDist)

                                this.isDistrito = fnDist.id
                            } catch (error) {
                                //console.log('err ubigeo ', error)
                            }
                            this.form.correo = correo
                            this.formClient = {
                                ...this.formClient,
                                ...resultado
                            }
                        }
                        this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                    } catch (error) {
                        this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                        //console.log('error ', error)
                    }
                }
            }
        },
        async resetForm() {
            this.formClient = DefaultForm.formClient()
            this.isDepartamento = null
            this.isProvincia = null
            this.isDistrito = null
            this.dataPersons = null
            this.$emit('cleanCache', this.formClient)
        },
        async addCliente() {
            try {
                this.$validator
                    .validateAll()
                    .then(async (result) => {
                        if (result) {
                            if (this.dataPersons) {
                                this.$store.commit('mainUI/OPEN_MODAL', {
                                    state: true,
                                    text: 'Actualizado Datos del Cliente',
                                    color: 'green'
                                })
                                console.log('this.dataPersons', this.dataPersons)
                                console.log('this.formClient', this.formClient)
                                await this.$store.dispatch('clientes/patchClient', {
                                    ...this.formClient,
                                    department_id: this.isDepartamento,
                                    province_id: this.isProvincia,
                                    district_id: this.isDistrito,
                                    id: this.dataPersons.id
                                })
                                console.log('this.listaClientes', this.listaClientes)
                                const _list = this.listaClientes
                                const index = _list.indexOf(_list.find((x) => x.id == this.dataPersons.id))
                                console.log('index', index)
                                _list[index] = {
                                    id: this.dataPersons.id,
                                    name: this.formClient.full_name,
                                    number: this.formClient.numeroDocumento,
                                    telephone: this.formClient.telefono,
                                    district_id: this.dataPersons.district_id,
                                    province_id: this.dataPersons.province_id,
                                    department_id: this.dataPersons.department_id,
                                    email: this.formClient.correo,
                                    address: this.formClient.direccion,
                                    identity_document_type_id: this.dataPersons.identity_document_type_id,
                                    person_type_id: this.dataPersons.person_type_id
                                }
                                console.log('_list[index]', _list[index])
                                console.log('_list[index]', _list)
                                this.$store.commit('clientes/UPDATE_CLIENTES', _list)

                                //const persons = await this.$store.dispatch('clientes/getClient', this.formClient.numeroDocumento)
                                // console.log('persons', persons)
                                // //await this.$store.dispatch('redis/syncRedis', {})
                                // await this.$store.commit('clientes/listaClientes', { active: 0 })
                                this.$store.commit('ventas/UPDATE_DATOS', {
                                    emailClient: this.formClient.correo,
                                    phoneClient: this.formClient.telefono
                                })
                                console.log('this.datos', this.datos)
                                this.$emit('getIdClient', this.dataPersons.id)
                                this.$emit('updateClient', true)
                                this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                            } else {
                                //console.log('result', result)
                                this.loading2 = true
                                //console.log('...this.formClient')
                                this.formClient.ubigeo = this.isDistrito
                                //console.log('...this.formClient')
                                ////console.log('this.formClient', this.formClient)
                                const { data } = await this.$store.dispatch('clientes/crearClienteFact', {
                                    ...this.formClient,
                                    condition: this.condition,
                                    state: this.state
                                })
                                console.log('---', data)
                                //console.log('this.listaClientes', this.listaClientes)
                                let _list = this.listaClientes
                                _list.push(data)
                                this.$store.commit('clientes/UPDATE_CLIENTES', _list)
                                console.log('here-1')
                                //console.log('this.listaClientes_2_)@', this.listaClientes)

                                ////console.log('this.listaClientes_V1', this.listaClientes)
                                // this.listaClientes.push(this.formClient)

                                // //console.log('this.listaClientes_V2', this.listaClientes)
                                this.$emit('getIdClient', data.id)

                                this.loading2 = false
                            } //42520633
                            this.formClient = DefaultForm.formClient()
                            this.isDepartamento = null
                            this.isProvincia = null
                            this.isDistrito = null
                        }
                    })
                    .catch((e) => {
                        //console.log('error', e)
                        this.loading2 = false
                        this.formClient = DefaultForm.formClient()
                        this.isDepartamento = null
                        this.isProvincia = null
                        this.isDistrito = null
                    })
            } catch (error) {
                console.log('error ', error)
                this.formClient = DefaultForm.formClient()
                this.isDepartamento = null
                this.isProvincia = null
                this.isDistrito = null
            }
        }
    },
    watch: {
        async isDepartamento(val) {
            //console.log('isDepartamento ', val)
            if (val) {
                await this.$store.dispatch('sedes/getProvincia', val)
            }
        },
        async isProvincia(val) {
            //console.log('provincia ', val)
            if (val) {
                await this.$store.dispatch('sedes/getDistrito', val)
            }
        },
        async _numeroDocumento() {
            this.getDataClients()
        },
        async _tipoDocumento() {
            this.getDataClients()
        }
    },
    mounted() {
        console.log('mounted -- crear cliente')
        this.formClient.idTipoDocumento = this.datos.idTipoDocumento
        this.formClient.numeroDocumento = this.datos.numeroDocumento
    }
}
</script>
